<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <div v-if="!(userData && roles)" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>

    <b-tabs
      v-if="userData && roles"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          :roles="roles"
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab v-if="userData.id !== null">
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Password</span>
        </template>
        <user-edit-tab-social :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <b-tab v-if="userData.id !== null">
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Profile</span>
        </template>
        <user-edit-tab-information :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import router from '@/router'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabPassword.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  data() {
    return {
      userData: null,
      roles: null,
      // companies: null,
    }
  },
  methods: {
    // fetchCities() {
    //   this.$http.get('/admin/v1/cities')
    //     .then(res => {
    //       const data = res.data
    //       const message = data.message

    //       if(!data.status) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: `Error`,
    //             icon: 'CoffeeIcon',
    //             variant: 'danger',
    //             text: message,
    //           },
    //         })
    //       }
    //       else {
    //         const currentData = res.data.data

    //         this.cities = []

    //         currentData.forEach((item, index) => {
    //           this.cities.push({
    //             label: item.name,
    //             value: item.name
    //           })
    //         })
            
    //       }
    //   })
    // },

    // fetchCompanies() {
    //   this.$http.get('/admin/v1/companies')
    //     .then(res => {
    //       const data = res.data
    //       const message = data.message

    //       if(!data.status) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: `Error`,
    //             icon: 'CoffeeIcon',
    //             variant: 'danger',
    //             text: message,
    //           },
    //         })
    //       }
    //       else {
    //         const currentData = res.data.data

    //         this.companies = []

    //         currentData.forEach((item, index) => {
    //           this.companies.push({
    //             label: item.name,
    //             value: item.id
    //           })
    //         })
            
    //       }
    //   })
    // },

    fetchRoles() {
      this.$http.get('/admin/v1/roles')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.roles = []

            currentData.forEach((item, index) => {
              this.roles.push({
                label: item.name,
                value: item.name
              })
            })
            
          }
      })
    },

    fetchUserData() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/users/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.userData = {
              id: currentData.id,
              name: currentData.name,
              last_name: currentData.last_name,
              salutation: currentData.salutation,
              email: currentData.email,
              status: (currentData.email_verified_at !== null),
              role: (currentData.roles !== null && currentData.roles.length > 0) ? currentData.roles[0].name : null,
              company: currentData.company,
              profile: (currentData.profiles !== null && currentData.profiles.length > 0) ? currentData.profiles[0] : null,
              company_country_id: (currentData.profiles !== null && currentData.profiles.length > 0 && currentData.profiles[0].company_city !== null) ? currentData.profiles[0].company_city.state.country.id : null,
              company_state_id: (currentData.profiles !== null&& currentData.profiles.length > 0 && currentData.profiles[0].company_city !== null) ? currentData.profiles[0].company_city.state.id : null,
              company_city_id: (currentData.profiles !== null && currentData.profiles.length > 0 && currentData.profiles[0].company_city !== null) ? currentData.profiles[0].company_city.id : null,
            }

            if(this.userData.profile === null) {
              this.userData.profile = {
                phone: "",
                company: "",
                company_address: "",
                company_city_id: "",
                company_state_id: "",
                company_country_id: "",
                company_phone: "",
                company_email: "",
                job_level: null,
                job_level_others: null,
                position: null,
                position_others: null,
                home_address: "",
                brand_name: "",
                company_size: "",
                industry: null,
                industry_others: null,
                // personal_email: ""
              }
            }
            else {
              this.userData.profile['job_level_others'] = this.userData.profile.job_level
              this.userData.profile['position_others'] = this.userData.profile.position
              this.userData.profile['industry_others'] = this.userData.profile.industry
            }
          
          }
      })
    }
  },
  created() {
    this.fetchUserData()
    this.fetchRoles()
    // this.fetchCompanies()
    // this.fetchCities()
  },
}
</script>
