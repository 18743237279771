<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Salutation -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Salutation"
            label-for="salutation"
          >
            <v-select
              v-model="userData.salutation"
              :options="salutations"
              :reduce="val => val.value"
              :clearable="false"
              input-id="salutation"
            />
          </b-form-group>
        </b-col>

        <!-- Field: First Name -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="First Name"
            label-for="first-name"
          >
            <b-form-input
              id="first-name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Last Name -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Last Name"
            label-for="last-name"
          >
            <b-form-input
              id="last-name"
              v-model="userData.last_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <b-form-checkbox
              :checked="userData.status"
              v-model="userData.status"
              name="check-button"
              switch
              inline
            >
              Verified
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="User Role"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role"
              :options="roles"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <div class="mt-2">
      <div style="float: left">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="save()"
          :disabled="!isValid()"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span>{{ (isLoading) ? "Loading..." : "Save" }}</span>
        </b-button>
      </div>
      <div style="float: right">
        <b-button size="md" variant="outline-primary" @click="back()">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Back</span>
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      salutations: [
        {
          label: "Mr. (Bapak)",
          value: "Mr"
        },
        {
          label: "Mrs. (Ibu)",
          value: "Mrs"
        }
      ]
    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  methods: {
    back() {
        this.$router.push('/users')
    },
    save() {
      this.isLoading = true

      const requestData = {
        name: this.userData.name,
        last_name: this.userData.last_name,
        salutation: this.userData.salutation,
        email: this.userData.email,
        role: this.userData.role,
        active: this.userData.status
      }

      if(this.userData.id) {
        this.$http.put('/admin/v1/users/' + this.userData.id, requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

            }

            this.isLoading = false
        })
      }
      else {
        this.$http.post('/admin/v1/users', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
            this.$router.replace("/users")
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Success`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: message,
                  },
                })
              })
            }

            this.isLoading = false
        })
      }
    }
    ,
    isValid() {
      return this.userData.name != "" && this.userData.email != "" && this.userData.role != "" && this.userData.salutation != ""
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
